@import './tailwindbase.css';

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

:root {
	--header-height: 84px;
	--header-mobile-height: 120px;
	--light-black: #333333;
	--trending-bg: linear-gradient(90deg, #ffecd2 0%, #fcb69f 100%);
	--lightBlue: #8bc2e9;
}

html,
body {
	max-width: 100vw;
	/* overflow-x: hidden; */
	background-color: #f3f3f3;
}

a {
	color: inherit;
	text-decoration: none;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
	-webkit-appearance: none;
	appearance: textfield;
}
@-webkit-keyframes autofill {
	0%,
	100% {
		color: transparent;
		background: transparent;
	}
}
input:-webkit-autofill {
	-webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #f3f3f3 inset !important;
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.swiper-slide > a {
	width: 100%;
}

.swiper-btn-next svg {
	display: flex;
	justify-content: center;
	margin: 0 auto;
}
.swiper-btn-prev svg {
	display: flex;
	justify-content: center;
	margin: 0 auto;
}

.swiper-btn-prev {
	background: #fff;
	border-radius: 100%;
	width: 65px;
	position: absolute;
	height: 65px;
	left: -30px !important;
	top: 50%;
	z-index: 10;
	cursor: pointer;
	transform: translate(0px, -50%);
	filter: drop-shadow(9px 11px 14px rgba(25, 65, 122, 0.25));
}
.swiper-btn-next {
	background: #fff;
	border-radius: 100%;
	width: 65px;
	position: absolute;
	height: 65px;
	cursor: pointer;
	z-index: 10;
	top: 50%;
	transform: translate(0px, -50%);
	right: -30px !important;
	filter: drop-shadow(9px 11px 14px rgba(25, 65, 122, 0.25));
}
.swiper-btn-next.small {
	width: 45px !important;
	height: 45px !important;
}
.swiper-btn-prev.small {
	width: 45px !important;
	height: 45px !important;
}
.swiper-btn-prev::after {
	font-size: 25px !important;
}

.swiper-btn-next::after {
	font-size: 25px !important;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.hide-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.thumnail-wrapper {
	height: calc(100vh - 100px);
	/* overflow: hidden; */
}

.thumnail-small {
	height: auto;
	box-sizing: border-box;
	padding: 10px 0;
}

.thumnail-small .swiper-slide {
	width: 25%;
	height: fit-content;
	aspect-ratio: 1/1;
	opacity: 0.4;
}

.thumnail-small .swiper-slide-thumb-active {
	opacity: 1;
}

.bg-primary-gradient {
	background: linear-gradient(57.45deg, #19437e 0.93%, #1951c1 98.64%), #1f549d;
}

.bg-footer-gradient {
	background: linear-gradient(
			80.61deg,
			rgba(25, 67, 126, 0.2) 4.73%,
			rgba(100, 150, 204, 0.2) 73.75%,
			rgba(150, 205, 255, 0.2) 96.43%
		),
		#1f549d;
}
.bg-accent-gradient {
	background: radial-gradient(
			108.4% 313.27% at -3.53% 109.28%,
			#b61337 0%,
			rgba(182, 19, 55, 0) 100%
		)
		#ff685f;
}
.bg-neutral-gradient {
	background: radial-gradient(
			108.4% 313.27% at -3.53% 109.28%,
			#004257 0%,
			rgba(19, 96, 182, 0) 100%
		)
		#4e9ab3;
}

/* Shadows */
.shadow-preview-product {
	box-shadow: 15px 25px 30px -17px rgba(0, 0, 0, 0.09);
}
.shadow-top {
	box-shadow: 0 -10px 20px -5px rgba(0, 0, 0, 0.25);
}
.shadow-right {
	box-shadow: 10px 0 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-bottom {
	box-shadow: 0 10px 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-left {
	box-shadow: -10px 0 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-all {
	box-shadow: 0 0 20px rgba(115, 115, 115, 0.75);
}
.shadow-top-right {
	box-shadow: 0 -10px 20px -5px rgba(115, 115, 115, 0.75),
		10px 0 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-top-bottom {
	box-shadow: 0 -10px 20px -5px rgba(115, 115, 115, 0.75),
		0 10px 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-top-left {
	box-shadow: 0 -10px 20px -5px rgba(115, 115, 115, 0.75),
		-10px 0 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-bottom-right {
	box-shadow: 0 10px 20px -5px rgba(115, 115, 115, 0.75), 10px 0 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-left-right {
	box-shadow: -10px 0 20px -5px rgba(115, 115, 115, 0.75),
		10px 0 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-bottom-left {
	box-shadow: 0 10px 20px -5px rgba(115, 115, 115, 0.75),
		-10px 0 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-top-bottom-right {
	box-shadow: 0 -10px 20px -5px rgba(115, 115, 115, 0.75),
		0 10px 20px -5px rgba(115, 115, 115, 0.75), 10px 0 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-top-bottom-left {
	box-shadow: 0 -10px 20px -5px rgba(115, 115, 115, 0.75),
		0 10px 20px -5px rgba(115, 115, 115, 0.75), -10px 0 20px -5px rgba(115, 115, 115, 0.75);
}
.shadow-inset {
	box-shadow: inset 0 0 20px rgba(115, 115, 115, 0.75);
}
.bg-glass-custom {
	background: rgba(255, 255, 255, 0.65);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
}

.shadow-range-slider {
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
}
.swiper-wrapper {
	@apply !flex-row;
}

.swiper-slide {
	@apply bg-transparent;
}

.slide-product {
	@apply !shadow-none;
}
.custom-extra-category {
	@apply lg:!w-[calc(40%-55px)] xl:!w-[calc(27%-70px)] 2xl:!w-[calc(22%-55px)];
}
/* .swiper-slide slide-product {
	@apply !w-1/3 p-[12px];
} */

.banner-swipper {
	.swiper-pagination {
		@apply flex items-center justify-center;
	}

	.swiper-pagination-bullet {
		@apply w-[10px] h-[10px] bg-white opacity-100;
	}
	.swiper-pagination-bullet.swiper-pagination-bullet-active {
		@apply w-[10px] h-[10px] p-[5px] border-[3px] border-solid border-[#23A79C];
	}
}

/* Add this to your CSS file (e.g., styles.css) */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.hide-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.custom-container {
	@apply container lg:max-w-[1340px];
}

.trending-wrapper {
	background: linear-gradient(90deg, #ffecd2 0%, #fcb69f 100%);
}
