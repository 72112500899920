@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
	html {
		font-family: Open sans, system-ui, sans-serif;
	}
	body {
		@apply text-base font-normal;
	}
	.btn-primary {
		@apply bg-primary hover:bg-primary-focus duration-150 transition-all;
	}
	.btn-secondary {
		@apply bg-secondary hover:bg-secondary-focus duration-150 transition-all;
	}
	.alignCenterScreen__sticky {
		@apply sticky top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%];
	}
	.alignCenterScreen__absolute {
		@apply absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%];
	}
	.alignCenterScreen__fixed {
		@apply fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%];
	}
	.slide-product {
		@apply rounded-small overflow-hidden shadow-productCard;
	}
	.activeBackToTop {
		@apply animate-[non-ef-opacity_1s_ease-in-out] !visible !flex;
	}
	.animate-toggle-height {
		@apply animate-[toggleHeight_300ms_ease-in-out];
	}
	.animate-revert-toggle-height {
		@apply animate-[revertToggleHeight_300ms_ease-in-out];
	}
	.hiddenBackToTop {
		@apply invisible;
	}
	.animation-300 {
		@apply transition-all duration-300;
	}
	.animation-200 {
		@apply transition-all duration-200;
	}
	.animation-100 {
		@apply transition-all duration-200;
	}
}

@layer components {
	/* input */
	.inputLabelInsideBorder {
		@apply absolute text-gray-600 text-small inline -top-[9px] left-[18px] right-[18px] bg-base-100 transition-all duration-100 w-fit z-[5];
	}
	.inputFormLabelInside {
		@apply hover:border-b-primary border-base-200 p-[18px] border border-b-[#E4E4E7] outline-none w-full text-base font-normal bg-transparent;
	}
	.inputWrapper input:focus + .inputLabelAnimation {
		@apply scale-90 animation-100 text-primary bg-[#f3f3f3] -translate-y-[calc(100%_+_12px)];
	}

	.inputFormBorderBottom {
		@apply bg-base-100 border-b border-l-0 border-t-0 border-r-0 border-gray-600 w-full text-sm outline-none rounded-t-[6px];
	}
	.inputLabelOnlyBorderBottom {
		@apply absolute text-gray-600 text-[12px] top-1.5 left-3 z-[5];
	}
	.inputWrapper {
		@apply relative;
	}
	.inputWrapper input:not([value='']) + .inputLabelAnimation {
		@apply bg-base-200 -translate-y-[calc(100%_+_12px)] scale-90 text-gray-600;
	}
	.inputWrapper:hover .inputLabelAnimation {
		@apply !text-primary transition-all duration-100;
	}
	.inputWrapper input:not([value='']) + .InputPlaceholder:not(.inputLabelAnimation) {
		@apply hidden;
	}
	.inputWrapper input:focus + .InputPlaceholder:not(.inputLabelAnimation) {
		@apply hidden;
	}
	.InputPlaceholder {
		@apply absolute pointer-events-none top-[50%] -left-[1px] -translate-y-[50%] bottom-0 text-base m-auto text-[#71717A] h-auto animation-100;
	}
	.InputPlaceholder span {
		@apply text-red-custom;
	}

	/* overflow global */
	.overflowHidden {
		overflow: hidden;
		margin: 0;
		touch-action: none;
		-ms-touch-action: none;
		/* position: fixed; we get rid of this line which resets the flow of the page */
		/* height: 100%; we change 100% to 100vh */
		height: 100vh;
	}
}
